import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { withStyles, Grid } from "@material-ui/core";

const Customers = ({ logos, classes }) => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { eq: "customers" } }) {
        frontmatter {
          logos {
            url
          }
        }
      }
    }
  `);

  const _logos = logos || data.markdownRemark.frontmatter.logos;

  return (
    <div className={classes.root}>
      <Grid container spacing={6} justify="center">
        {_logos.map((logo) => (
          <Grid
            key={logo.url}
            container
            item
            justify="center"
            alignItems="flex-end"
            xs={4}
            sm={3}
            md={2}
          >
            <img
              className={`lazyload ${classes.client_logo}`}
              data-src={logo.url}
              alt=""
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const styles = (theme) => ({
  heading: {
    color: "#28315e",
    fontSize: 24,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 64,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
    },
  },
  client_logo: {
    maxWidth: "100%",
    display: "block",
  },
});

export default withStyles(styles)(Customers);
