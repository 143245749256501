import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  feature: {
    color: "#28315E",
  },
  icon: {
    width: 120,
    height: 120,
    maxWidth: "none",
    marginBottom: 20,
    display: "block",
    objectFit: "contain",
  },
  title: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    color: "#546fff",
  },
  summary: {
    margin: 0,
    fontSize: 14,
    marginTop: 10,
    color: "#354454",
  },
});

const Feature = ({ feature, iconWidth, iconHeight }) => {
  const classes = useStyles();

  let iconStyle = {};
  if (iconWidth || iconHeight) {
    iconStyle = {
      width: "auto",
      height: "auto",
    };
  }
  iconWidth && (iconStyle.width = iconWidth);
  iconHeight && (iconStyle.height = iconHeight);

  return (
    <div key={feature.title} id={feature.title} className={classes.feature}>
      <img
        className={`lazyload ${classes.icon}`}
        style={iconStyle}
        data-src={`${feature.icon}?w=${iconWidth}&h=${iconHeight}`}
        alt=""
      />
      <p className={classes.title}>{feature.title}</p>
      <p className={classes.summary}>{feature.summary}</p>
    </div>
  );
};

export default Feature;
