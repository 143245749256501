import React from "react";
import { graphql } from "gatsby";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ContactForm from "../components/contact";
import Customers from "../components/customers";
import Feature from "../components/feature";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { scheduleDiscoveryCall } from "../utils/calendly";

const useStyles = makeStyles((theme) => ({
  row: {
    paddingTop: 80,
    paddingBottom: 80,
    color: "#fff",
  },
  media: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: 300,
    objectFit: "contain",
    outline: 0,
    display: "block",
    [theme.breakpoints.up("sm")]: {
      objectPosition: "right",
    },
  },
  heading: {
    color: "rgb(41, 57, 99)",
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: 28,
    },
  },
  heroHeading: {
    color: "#23325F",
    fontSize: 26,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 20,
    lineHeight: 1.3,
    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },
  heroHeading2: {
    color: "#68798D",
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    },
  },
  c_title1: {
    marginBottom: 12,
    fontSize: 18,
    fontWeight: 500,
    color: "#293963",
    [theme.breakpoints.up("sm")]: {
      fontSize: 24,
    },
  },
  c_summary: {
    fontSize: 14,
    lineHeight: 1.8,
    color: "#354454",
  },
  c_link: {
    fontSize: 14,
    fontWeight: 600,
    display: "inline-flex",
    alignItems: "center",
  },
  c_media: {
    maxHeight: 320,
    width: "100%",
    objectFit: "contain",
  },
  e_box: {
    border: "1px solid #f1f1f1",
    boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.04)",
    borderRadius: 8,
    height: "100%",
    overflow: "hidden",
    padding: 40,
    cursor: "pointer",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease-in",
    "&:hover": {
      backgroundColor: "#475597",
      // border: '1px solid #BDC8FF',
      boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.04)",
      "& $e_title1": {
        color: "rgba(255, 255, 255, 0.6)",
      },
      "& $e_title2": {
        color: "#fff",
      },
      "& $e_body": {
        color: "rgba(255, 255, 255, 0.6)",
      },
      "& $e_cta": {
        color: "#fff",
      },
    },
  },
  e_box_inner: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  e_image: {
    width: "100%",
    height: 120,
    objectFit: "cover",
    background: "#505D9F",
  },
  e_title1: {
    fontSize: 12,
    marginBottom: 4,
    letterSpacing: "0.12em",
    textTransform: "uppercase",
    color: "#546fff",
  },
  e_title2: {
    color: "#354454",
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: "0.02em",
    marginBottom: 22,
  },
  e_body: {
    fontSize: 14,
    lineHeight: 1.8,
    color: "#354454",
    flex: 1,
  },
  e_cta: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: "0.04em",
    display: "flex",
    alignItems: "center",
    marginTop: 42,
    color: "#546fff",
  },
  client_logo: {
    maxHeight: 40,
    maxWidth: "100%",
    display: "block",
  },
  bg1: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "linear-gradient(118.95deg, #EFF2FF 34.15%, #F5F7FF 83.72%)",
      clipPath: "polygon(0 20%, 100% 0, 100% 100%, 0% 100%)",
    },
  },
  bg2: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "linear-gradient(143.49deg, #F4F6FF 21.27%, #EFF2FF 65.37%)",
      clipPath: "polygon(100% 13%, 100% 82%, 31% 100%, 0 100%, 0 0);",
    },
  },
  table: {
    color: "#354454",
    fontSize: 10,
    borderCollapse: "collapse",
    "& tr + tr td": {
      borderTop: "1px solid rgba(84, 111, 255, 0.37)",
    },
    "& td": {
      textAlign: "center",
      height: 64,
      minWidth: 100,
      padding: 8,
      background: "#fff",
      "&:nth-child(2)": {
        boxShadow: "0px 4px 8px #E3E3E3",
        borderLeft: "1px solid rgba(84, 111, 255, 0.37)",
        borderRight: "1px solid rgba(84, 111, 255, 0.37)",
        background: "#EFF2FF",
        position: "relative",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
        padding: "8px 48px",
      },
    },
    "& td:first-child": {
      textAlign: "left",
    },
  },
  nanonetsLogoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#546fff",
    borderRadius: "4px 4px 0 0",
    position: "absolute",
    zIndex: 10,
    left: 0,
    right: 0,
    top: -16,
    bottom: 0,
  },
  reasons: {
    display: "grid",
    gridGap: 120,
    [theme.breakpoints.up("sm")]: {
      gridGap: "96px 120px",
      gridTemplateColumns: "1fr 1fr",
    },
  },
}));

const OCR = ({ data }) => {
  const classes = useStyles();

  const {
    seoTitle,
    seoDescription,
    heroSection,
    reasons,
    whyNanonets,
    ctaBlock,
    testimonials,
    customers,
    comparison,
  } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />

      <section>
        <Container>
          <Grid
            container
            spacing={6}
            alignItems="center"
            className={classes.row}
          >
            <Grid item xs={12} sm={6}>
              <div>
                <p className={classes.heroHeading}>{heroSection.title1}</p>
                <p className={classes.heroHeading2}>{heroSection.title2}</p>
                <div style={{ marginTop: 30 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={"auto"}>
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{ width: "100%" }}
                        onClick={() => {
                          window.open("https://app.nanonets.com", "_blank");
                        }}
                      >
                        Get started for free
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ width: "100%" }}
                        onClick={scheduleDiscoveryCall}
                      >
                        Request a demo
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <img
                className={classes.media}
                src={heroSection.image}
                alt={heroSection.altText}
              />
            </Grid>
          </Grid>
        </Container>
      </section>

      <section
        style={{ background: "#EFF2FF", paddingTop: 64, paddingBottom: 72 }}
      >
        <Container>
          <Grid container justify="center">
            <Grid item md={10}>
              <div className={classes.heading}>{customers.title}</div>
            </Grid>
          </Grid>

          <div style={{ marginTop: 72 }}>
            <Customers />
          </div>
        </Container>
      </section>

      <section style={{ paddingTop: 120, paddingBottom: 40 }}>
        <Container>
          <Grid container justify="center">
            <Grid item md={10}>
              <div className={classes.heading} style={{ paddingBottom: 80 }}>
                {whyNanonets.title}
              </div>
            </Grid>
          </Grid>

          <div>
            {whyNanonets.points.map((item, index) => (
              <Grid
                key={index}
                container
                spacing={2}
                alignItems="center"
                direction={index % 2 === 0 ? "row" : "row-reverse"}
                style={{ paddingBottom: 80 }}
              >
                <Grid item xs={12} sm>
                  <div className={classes.c_title1}>{item.title}</div>
                  <div className={classes.c_summary}>{item.summary}</div>
                </Grid>

                <Grid item xs={12} sm={1}></Grid>

                <Grid item xs={12} sm>
                  <img
                    data-src={item.image}
                    className={`lazyload ${classes.c_media}`}
                    alt={item.altText}
                  />
                </Grid>
              </Grid>
            ))}
          </div>
        </Container>
      </section>

      <section
        style={{
          background: "linear-gradient(0deg, #48559E -52.78%, #283577 90.59%)",
          color: "#fff",
          textAlign: "center",
          paddingTop: 60,
          paddingBottom: 60,
        }}
      >
        <Container>
          <Grid container justify="center">
            <Grid item md={10}>
              <div>
                <p
                  className={classes.heading}
                  style={{ marginTop: 0, marginBottom: 40, color: "inherit" }}
                >
                  {ctaBlock.title}
                </p>

                <p style={{ marginTop: 36, marginBottom: 24, fontSize: 16 }}>
                  {ctaBlock.title2}
                </p>
              </div>
            </Grid>

            <Grid container spacing={2} justify={"center"}>
              <Grid item xs={12} sm="auto">
                <Button
                  variant="outlined"
                  style={{
                    width: "100%",
                    color: "#fff",
                    border: "1px solid #fff",
                  }}
                  onClick={() => {
                    window.open("https://app.nanonets.com", "_blank");
                  }}
                >
                  Get started for free
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button
                  color="primary"
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={scheduleDiscoveryCall}
                >
                  Request a demo
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section style={{ paddingTop: 120 }}>
        <Container>
          <Grid container justify="center">
            <Grid item md={10}>
              <div className={classes.heading} style={{ paddingBottom: 96 }}>
                {comparison.title}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={4} style={{ overflow: "auto" }}>
            <Grid item xs={12}>
              <div
                style={{
                  display: "grid",
                  boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.1)",
                  border: "1px solid rgba(84, 111, 255, 0.37)",
                }}
              >
                <table className={classes.table}>
                  <tbody>
                    {comparison.rows.map((row, index1) => (
                      <tr key={index1}>
                        {row.cells.map((cell, index2) => (
                          <td key={index2}>
                            {cell.text.startsWith("/media/") ? (
                              <div
                                className={clsx(
                                  index1 === 0 &&
                                    index2 === 1 &&
                                    classes.nanonetsLogoContainer
                                )}
                              >
                                <img
                                  data-src={cell.text}
                                  alt=""
                                  className="lazyload"
                                  style={{
                                    width: 112,
                                    maxWidth: "100%",
                                    display: "block",
                                    margin: "auto",
                                    padding: 4,
                                  }}
                                />
                              </div>
                            ) : cell.text === "yes" ? (
                              <CheckCircleIcon
                                style={{ color: "#41AD49", fontSize: 20 }}
                              />
                            ) : cell.text === "no" ? (
                              <CancelIcon
                                style={{ color: "#FC3414", fontSize: 20 }}
                              />
                            ) : (
                              <span>{cell.text}</span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section style={{ paddingTop: 120, paddingBottom: 96 }}>
        <Container>
          <Grid container justify="center">
            <Grid item md={10}>
              <div className={classes.heading} style={{ paddingBottom: 80 }}>
                {reasons.title}
              </div>
            </Grid>
          </Grid>

          <div className={classes.reasons}>
            {reasons.cards.map((feature, index) => (
              <Grid key={index}>
                <Feature feature={feature} iconHeight={48} iconWidth={48} />
              </Grid>
            ))}
          </div>
        </Container>
      </section>

      <section
        style={{ paddingTop: 80, paddingBottom: 320, background: "#EFF2FF" }}
      >
        <Container style={{ position: "relative" }}>
          <div>
            <Grid container justify="center">
              <Grid item md={10}>
                <div className={classes.heading} style={{ paddingBottom: 64 }}>
                  {testimonials.title}
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={6}>
              {testimonials.cards.map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <div
                    className={classes.e_box}
                    // onClick={() => {
                    //   window.open(item.link, "_blank");
                    // }}
                  >
                    <div className={classes.e_box_inner}>
                      <div className={classes.e_title1}>{item.title1}</div>
                      <div className={classes.e_title2}>{item.title2}</div>
                      <div className={classes.e_body}>{item.body}</div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
      </section>

      <ContactForm />
    </Layout>
  );
};

export default OCR;

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        seoTitle
        seoDescription
        heroSection {
          title1
          title2
          image
          altText
        }
        reasons {
          title
          cards {
            title
            summary
            icon
          }
        }
        whyNanonets {
          title
          points {
            title
            summary
            image
            altText
          }
        }
        ctaBlock {
          title
          title2
        }
        testimonials {
          title
          cards {
            title1
            title2
            body
            ctaText
            link
          }
        }
        customers {
          title
          logos {
            url
          }
        }
        comparison {
          title
          rows {
            cells {
              text
            }
          }
        }
      }
    }
  }
`;
